/** @jsx jsx */

import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { h3, p, jsx } from 'theme-ui'

const ReactMarkdown = require('react-markdown')

export default ({ article }) => (
  <>
    <Img alt="" fluid={article.heroImage.fluid} />
    <div>
      <div
        sx={{
          fontSize: 2,
        }}
      >
        <Link to={`/blog/${article.slug}`}>{article.title}</Link>
      </div>
      <small>{article.publishDate}</small>

      <ReactMarkdown
        source={article.description.childMarkdownRemark.rawMarkdownBody}
      />

      {article.tags.map(tag => (
        <p key={tag}>{tag}</p>
      ))}
    </div>
  </>
)
